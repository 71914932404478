<template>
<div class="Registry">
     <ContainerCard  :width="61">
        <div class="info">
        <div class="infomain">
          <div class="info-title">
              <span class="label">服务注册协议</span>
         </div>
         <div class="standard">
            <p class="artContent" 
           >在使用平台服务之前，请您务必审慎阅读、充分理解本协议(如果您未满16周岁，或已满16周岁未满18周岁且不能以自己的劳动收入作为主要收入来源的，请在法定监护人的陪同下阅读本协议）各项条款，特别是限制或免除责任条款、隐私保护政策、帐号规则、法律适用和争议解决条款（包括管辖条款），以及其它以加粗加黑和/或加下划线等显示形式提示您注意的重要条款，请务必重点查阅。若您不同意本协议，则您有充分且完全的权利退出使用平台服务（但这并不妨碍您浏览平台中的商品信息），您通过网络页面点击注册和/或实际使用平台服务的行为即视为您已阅读、理解并同意接受本协议。如果您对本协议有任何的疑问、投诉、意见和建议，欢迎您通过本协议所附联系方式与我们沟通反馈，我们十分乐意为您解答。</p>
         </div>
          
          <div class="standard">
            <p class="artContent">1.基础约定</p>
            <ul>
                <li >1.1.协议范围：考虑到互联网服务以及产品频繁迭代更新等行业特点，为了更全面的界定您与平台之间的权利义务，本协议包括我们根据法律法规规定以及本协议制定的其他政策、规则、公告声明等（除非特有所指，合称为“本协议”），您也应当加以遵守。</li>
                <li >1.2.服务范围：我们可能通过不断丰富的功能界面向您提供本协议项下的平台服务，包括但不限于移动应用程序（“APP”）、PC端网站、小程序以及其他形式。具体以我们实时发布的服务功能界面范围为准。</li>
                <li >1.3.修改与更新：我们有权遵照法律法规规定，在必要时修改本协议（包括适时制定并发布其他政策、规则、公告声明），更新后的协议条款将代替原来的协议并在法律规定的期限届满后正式生效，您可以在相关服务页面查阅最新版本的协议条款。本协议修改后，如果您继续使用平台服务的，即视为您已接受修改后的协议。如果您不接受修改后的协议，则您有权利停止使用平台服务。</li>
                <li >1.4.平台授予用户一项个人的、不可转让及免费使用（除本协议另有声明外）的许可，以供您使用平台服务，用户可以以非商业目的使用。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">2.平台服务内容</p>
            <ul>
                <li >2.1.平台为您提供丰富的平台服务。</li>
                <li >2.2.平台建立便捷有效的投诉举报机制并将尽最大努力及时受理并处理您的投诉举报，同时，当您在平台发生购物争议时，若您同意平台争议解决规则，则我们亦将协助您处理您的争议。您同意平台有权作为独立的第三方，根据平台所了解到的争议事实并依据平台争议解决规则，以普通人的注意义务程度，独立的作出调处决定。如您对调处决定不满意，您仍有权采取其他法定争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您同意先履行调处决定。</li>
                <li >2.3.为交易达成以及提升用户体验等目的，平台亦将为您提供更为友好完善的平台服务，并优化和引入第三方服务。由此发生的法律关系是由您和该等第三方服务商之间形成，和平台无关。</li>
                <li >2.4.您同意并知悉，平台中的具体内容、功能和形式由平台根据实际情况按实际可见的状态提供，我们有权自行确定平台服务的具体内容、功能和形式（例如，我们可能仅在特定的国家或地区以特定的语言提供），有权自行决定增加、变更、中断和停止平台具体的内容、功能和形式。具体以平台实时呈现的服务内容、功能和形式为准。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">3.用户帐号规则</p>
            <ul>
                <li >3.1.您在注册或使用平台服务时可能需要提供一些必要的信息。您须保证所填写及所提供的资料真实、准确、完整，否则可能无法使用平台服务，或在使用过程中受到限制，甚至影响您是否能够使用平台特定功能。对于因您提供的信息不真实、不准确或不完整导致的责任和损失由您承担。</li>
                <li >3.2.平台有权根据您所提供资料的真实性、准确性、完整性以及是否符合平台服务规定的其他条件，决定是否为您提供服务。您在使用平台服务过程中，所提供的信息发生变动，应及时进行变更。</li>
                <li >3.3.您注册成功后，我们将为您配置帐号，该帐号之所有权归平台所有，用户在本协议有效期内仅享有使用权。未经平台同意，用户的帐号仅供用户本人使用，帐号信息和权限不得以转让、赠与、借用、售卖、授权或租用等方式许可给第三方使用或和第三方共同控制、使用。</li>
                <li >3.4.您知悉并理解，如不同帐号绑定或关联同一手机号、同一支付账户、同一身份证信息、同一设备号或相同收货信息，所述不同帐号将可能被视为关联帐号（即同一用户），在此情况下，我们有权进行合并或强制注销相关帐号。</li>
                <li >3.5.如果您需要注销您的帐号，您有权通过平台公布的方式、程序进行，我们将在核实您的身份并清理帐号资产（例如已充值或已获得的虚拟资产等）及纠纷争议后，为您提供帐号注销服务。在注销帐号之后本协议即终止，我们将停止为您提供任何服务。当然，我们也保留权利在您的帐号符合回收的如下情况下，直接回收并注销您的帐号并停止服务：（1）未通过实名认证；（2）连续12个月未曾登陆、使用平台服务；（3）其它情况。</li>
                <li >3.6.用户一经注册，即可能可以在平台及我们关联公司运营的其它产品，或者合作伙伴等第三方平台中通行使用。在使用平台服务帐号登录并使用其他产品或服务时，除遵守本协议约定外，还应遵守该等产品的服务协议的约定。平台服务和其他产品对可能出现的纠纷在法律规定和约定的范围内各自承担责任。 </li>
                <li >3.7.为客户体验及功能服务考虑，您在使用不同服务时被要求填写的信息内容可能不完全一致，因此，在使用更高级别服务时，为了给您提供更好的服务及为了您的登录帐号安全，可能需要您补充完整信息后方可使用。 </li>
                <li >3.8.您有责任自行负责保管帐号的用户名和密码等信息，否则因该等事项引发的法律责任由用户自行承担。凡使用平台服务登录帐号和登录凭证的行为，平台服务视为您本人的操作，操作所产生的电子信息记录均为平台服务用户行为的有效凭据。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">4.用户行为规范</p>
            <ul>
                <li >4.1.您保证合理地使用平台服务，并接受本协议和我们适时制定并发布的其他政策、规则、公告声明。</li>
                <li >4.2.行为禁止：您可在本协议约定的范围内使用平台及服务，您不得利用平台从事以下行为：</li>
                <div>
                    <ul>
                        <li >（1）超出授权或恶意使用平台服务；</li>
                        <li >（2）利用平台发表、传送、传播、储存危害国家安全、国家统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容，或者设置含有上述内容的网名、角色名，发布、传送、传播违法广告信息、营销信息及垃圾信息等；</li>
                        <li >（3）利用平台侵害他人知识产权、肖像权、隐私权、名誉权、个人信息等合法权利或权益；</li>
                        <li >（4）恶意虚构或协助虚构事实、评价等信息或数据；</li>
                        <li >（5）进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本“软件”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称；利用平台及服务上传任何病毒、木马，或者蠕虫等危害网络健康的内容；</li>
                        <li >（6）对平台进行反向工程、反向编译或反向汇编或以其它方式企图发现平台的源代码和算法，未经许可修改、禁用软件的任何功能或创建基于软件的衍生作品。去除平台或文档上的任何所有权声明或标签，或将其他软件与平台合并；</li>
                        <li >（7）进行任何破坏我们提供服务公平性或者其他影响应用正常秩序的行为，如主动或被动刷分、合伙作弊、使用外挂或者其他的作弊软件、利用BUG（又叫“漏洞”或者“缺陷”）来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众；</li>
                        <li >（8）从事其他法律法规、政策及公序良俗、社会公德禁止的行为以及侵犯其他个人、公司、社会团体、组织的合法权益的行为。</li>
                    </ul>
                </div>
                 <li >4.3.信息内容规范：为了营造良好网络生态，保障公民、法人和其他组织的合法权益，维护国家安全和公共利益，我们将根据《中华人民共和国国家安全法》《中华人民共和国网络安全法》《互联网信息服务管理办法》等法律、行政法规，自觉践行社会主义核心价值观，营造清朗的网络空间，并开展的弘扬正能量、处置违法和不良信息等相关活动。</li>
                 <li >4.3.1.我们鼓励您制作、复制、发布含有下列内容的信息：</li>
                    <div>
                        <ul>
                            <li >（1）超出授权或恶意使用平台服务；</li>
                            <li >（2）宣传党的理论路线方针政策和中央重大决策部署的；</li>
                            <li >（3）展示经济社会发展亮点，反映人民群众伟大奋斗和火热生活的；</li>
                            <li >（4）弘扬社会主义核心价值观，宣传优秀道德文化和时代精神，充分展现中华民族昂扬向上精神风貌的；</li>
                            <li >（5）有效回应社会关切，解疑释惑，析事明理，有助于引导群众形成共识的；</li>
                            <li >（6）有助于提高中华文化国际影响力，向世界展现真实立体全面的中国的；</li>
                            <li >（7）其他讲品味讲格调讲责任、讴歌真善美、促进团结稳定等的内容。</li>
                        </ul>
                    </div>
                 <li>4.3.2.您不得制作、复制、发布含有下列内容的违法信息：</li>
                     <div>
                        <ul>
                            <li >（1）超出授权或恶意使用平台服务；</li>
                            <li>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>
                            <li >（3）损害国家荣誉和利益的；</li>
                            <li >（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</li>
                            <li >（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</li>
                            <li >（6）煽动民族仇恨、民族歧视，破坏民族团结的；</li>
                            <li >（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</li>
                            <li >（8）散布谣言，扰乱经济秩序和社会秩序的；</li>
                            <li >（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
                            <li>（10）侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</li>
                            <li>（11）法律、行政法规禁止的其他内容。</li>
                        </ul>
                    </div>
                <li >4.3.3.我们也将依法采取措施，防范和抵制制作、复制、发布含有下列内容的不良信息：</li>
                     <div>
                        <ul>
                            <li>（1）使用夸张标题，内容与标题严重不符的；</li>
                            <li>（2）炒作绯闻、丑闻、劣迹等的；</li>
                            <li>（3）不当评述自然灾害、重大事故等灾难的；</li>
                            <li>（4）带有性暗示、性挑逗等易使人产生性联想的；</li>
                            <li>（5）展现血腥、惊悚、残忍等致人身心不适的；</li>
                            <li>（6）煽动人群歧视、地域歧视等的；</li>
                            <li>（7）宣扬低俗、庸俗、媚俗内容的；</li>
                            <li>（8）可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；</li>
                            <li>（9）其他对网络生态造成不良影响的内容。</li>
                        </ul>
                    </div>
                 <li >4.4.信息内容的使用规范</li>
                 <li >4.4.1.未经我们书面许可，您不得自行或授权、允许、协助任何第三人对平台中信息内容进行如下行为：</li>
                     <div>
                        <ul>
                            <li>（1）复制、读取、采用平台的信息内容，用于任何形式的商业用途；</li>
                            <li>（2）擅自编辑、整理、编排平台及相关服务的信息内容后在平台及相关服务的源页面以外的渠道进行展示；</li>
                            <li>（3）采用不正当方式，自行或协助第三人对平台及相关服务的信息内容产生流量、阅读量或交易引导、转移、劫持等不利影响。</li>
                        </ul>
                    </div>
                
                
            </ul>
          </div>


          <div class="standard">
            <p class="artContent">5.个人信息保护与隐私政策</p>
            <ul>
                <li >5.1.尊重用户隐私并保护您的个人信息安全是我们的一贯态度，平台将会采取合理的措施保护您的个人信息与隐私。我们承诺，除非获得用户同意，平台不会收集、使用其提供服务所必需以外的用户个人信息或者将信息用于提供服务之外的目的。</li>
                <li >5.2.安全保护：我们将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息及隐私安全，以免遭受未经授权的访问、使用或披露。</li>
                <li >5.3.信息使用与披露：我们有权在遵守法律法规的前提下，以明示的方式获取、使用、储存和分享您的个人信息。我们不会在未经您授权时，公开、编辑或透露您的个人信息及您保存在我们的非公开内容。您同意并保证：平台有权根据我们制定的个人信息保护规则收集使用您的相关信息。</li>
                <li >5.4.您同意，我们有权通过cookie等技术收集您的使用、行为信息，并在经过数据脱敏使之不再指向、关联到您个人的身份信息时，自由使用脱敏后的纯商业数据。当然，您也可根据自己的偏好删除Cookie，但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。目前删除Cookie的一般路径是浏览器：“设置-清除数据”，或者将手机系统还原/清除。</li>
                <li >5.5.在遵守本协议项下特别约定的个人信息保护与隐私政策外，我们希望您认真并完整阅读我们特别针对平台而制定并适时发布的《隐私权政策》，这将更有助于保障您的个人信息。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">6.信息或广告推送</p>
            <ul>
                <li >6.1.您同意在接受我们提供服务的同时，允许我们在遵守法律法规的前提下自行或由第三方广告商向您发送、展示广告、推广或宣传信息（包括商业与非商业信息）。如您对发送、推荐的广告或信息不感兴趣，您可以基于我们提供的相关技术选项，控制系统向您展示或不展示/减少展示相关类型的广告或信息。 </li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">7.知识产权等权益</p>
            <ul>
                <li >7.1.我们对平台中所包含的受知识产权或其他法律保护的资料享有相应的权利；除依法由用户享有著作权的内容之外，平台的整体内容的知识产权归我们或关联公司所有。 </li>
                <li >7.2.您在使用我们服务时利用平台及服务所发表上传的文字、图片、视频、软件以及表演等原创信息的知识产权归您所有（或由第三方内容提供方和您另行约定），但是基于平台运营的需求，您确认您对该等信息的发表、上传行为视同为对我们授权知识产权的使用、复制、传播等权利。 </li>
                <li >7.3.基于对数据的合法加工而获得的具有竞争性的数据权益，除法律法规另有规定外，我们享有独立的使用权益而无须获得您的同意。</li>
                <li >7.4.关于信息内容的投诉或举报。若您在使用平台过程中，不慎受到合法权益的侵犯，您有权通知我们采取必要措施进行处置。若您在使用平台过程中，发现存在违法违规或违反本服务相关规则的情况，您也有权向我们发起举报，我们亦会及时采取必要措施（删除、屏蔽、断开链接或限制使用功能等）进行处置。</li>
                <li >7.5.内容维权授权。在法律法规允许的范围内，您同意并授权我们就侵犯您合法权益的行为（包括但不限于私自复制、使用、编辑、抄袭等行为）采取任何形式的法律行动，包括但不限于投诉、诉讼等必要的维权措施。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">8.免责声明</p>
            <ul>
                <li >8.1.我们对于您自平台而获得的所有他人、第三方的信息、内容或者广告宣传等任何资讯（以下统称“信息”），除法律明确规定外，不保证真实、准确和完整性。如果任何单位或者个人通过上述“信息”而进行任何行为，您须自行甄别真伪和谨慎预防风险。您在接受平台及服务时，有可能会接触到令人不快、不适当或令人厌恶的内容，在任何情况下，我们均不对任何此等内容承担任何责任。无论何种原因，我们不对任何非与我们直接发生的交易或行为承担任何直接、间接、附带或衍生的损失和责任。</li>
                <li >8.2.鉴于互联网服务的特殊性，您理解并同意我们在以下情况下无需对您所遭受的损失（包括但不限于财产、收益、数据资料等方面的损失或其它无形损失）承担责任：</li>
                <div>
                    <ul>
                        <li>（1）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素导致平台及服务障碍不能正常运作；</li>
                        <li>（2）由于计算机病毒、木马、其他恶意程序、黑客攻击、电信部门及网络运营公司技术调整或故障、系统维护等意外原因而造成平台及服务中断或延迟。</li>
                    </ul>
                </div>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">9.违约责任</p>
            <ul>
                <li >9.1.如果我们发现、收到他人举报或投诉您违反本协议约定或违反法律法规和国家有关规定的，我们有权采取警示、冻结、终止/中止/限制使用帐号等功能、解除或终止本协议，或提出损害赔偿等措施。</li>
                <li >9.2.若您的行为给我们造成损失的（包括但不限于直接损失、名誉损失、第三方的罚款、索赔等），我们有权全额向您追偿，如您在平台中有保证金、虚拟货币等财产或优惠券等虚拟权益的，我们有权冻结。</li>
                <li >9.3.本协议终止后，除法律有明确规定外，平台无义务向您或您指定的第三方披露您账户中的任何信息。本协议终止后，平台仍享有下列权利：根据法律规定，继续保存您留存于平台的的各类信息；对于您过往的违约行为，平台仍可依据本协议向您追究违约责任。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">10.法律适用与管辖</p>
            <ul>
                <li >10.1.本协议的订立、执行、解释及争议的解决均适用中华人民共和国法律。</li>
                <li >10.2.如双方就平台服务协议内容或其履行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向协议签订地，即法院提起诉讼。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">11.如何联系我们</p>
            <ul>
                <li >11.1.如果您有任何的疑问、投诉、意见和建议，欢迎您与我们沟通反馈。我们的联系方式见下：</li>
            </ul>
          </div>

          <div class="call-center">
            客服热线： 0595-22907977   
          </div>
        </div>
      </div>
     </ContainerCard>
</div>
  
</template>

<script>
import HomeNav from "../Layout/components/HomeNav.vue";
import Footer from "../Layout/components/Footer.vue";
import ContainerCard from "../../components/newContainerCard.vue";
export default {
    name:'Registry',
    data() {
        return{

        }
    },
    components:{ HomeNav , Footer ,ContainerCard }

}
</script>

<style scoped lang="scss">
// .Registry {
//     margin:  0 auto;
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
// }

// .registryText {
//     // display: flex;
//     // flex-direction:column;
//     // flex-wrap: wrap;
//     width: 756px;
//     background: red;
//     margin: 0 auto;
//     padding: 20px 50px 36px 50px;
//     font-size: 16px;
//     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
//     font-weight: 400;
//     color: #404040;
//     line-height: 36px;
//     text-align: justify;
// }

.Registry {
    .info {
  margin-bottom: 20px;
  margin-top: 24px;
  .infomain {
    margin: 20px 0;
    ul {
        text-align: justify;
    }
    li {
        line-height: 24px;
    }
    .info-title {
        margin-bottom: 24px;
        text-align: center;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 600;
          color: #101010;
          line-height: 24px;
        }
      }
    .standard {
    .artContent {
      margin-top: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #404040;
      line-height: 36px;
      text-align: justify;
    }

    }

    .call-center {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        text-align: justify;
    }
  }
}

}

</style>